<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>{{ title }}</strong>

        <a-button
          type="reset"
          class="btn btn-sm btn-outline-danger mb-0 ml-3"
          @click="back()"
        >
          Back
        </a-button>
        <a-button
          class="btn btn-outline-success ml-3"
          ref="buttonsave"
          @click="handleSubmit"
          >Save Changes</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
          <div class="row">
            <div
              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2 mb-3"
              v-if="action === 'update'"
            >
              <label>No. Rekening</label>
            </div>
            <div
              class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1 mb-3"
              v-if="action === 'update'"
            >
              <input
                :readOnly="true"
                style="width: 30px"
                :maxlength="2"
                class="ant-input mr-2"
                v-model="tempInput.f2digitrek"
                placeholder=""
                @keydown.native.enter="getDataRekening"
              />
              <input
                :readOnly="true"
                style="width: 30px"
                :maxlength="2"
                class="ant-input mr-2"
                v-model="tempInput.s2digitrek"
                placeholder=""
                @keydown.native.enter="getDataRekening"
              />
              <the-mask
                @keydown.native.enter="getDataRekening"
                :readOnly="true"
                class="ant-input"
                :mask="['######']"
                :masked="true"
                style="width: 150px"
                placeholder="No. Rekening"
                v-model="tempInput.last6rek"
              ></the-mask>
              <!-- @input="callback" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">Tanggal Pembukaan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-date-picker
                v-if="action === 'create'"
                style="width: 120px"
                format="DD-MM-YYYY"
                v-model="formData.tgl"
                placeholder="Tanggal Register"
                :allowClear="false"
              />
              <input
                v-else
                :readOnly="true"
                style="width: 120px"
                class="ant-input"
                v-model="formData.tgl"
                placeholder="Tanggal Register"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>No. Register</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <input
                :readOnly="false"
                style="width: 30px"
                :maxlength="2"
                class="ant-input mr-2"
                v-model="tempInput.f2digitreg"
                placeholder="Base"
              />
              <the-mask
                :readOnly="disabledAllInputExcludeNoRekening"
                class="ant-input"
                :mask="['######']"
                :masked="true"
                @keydown.native.enter="changeKode"
                style="width: 150px"
                placeholder="No. Register"
                v-model="tempInput.last6reg"
                ref="inreg"
              ></the-mask>
              <!-- @input="callback" -->
              <a-button
                class="btn btn-outline-success ml-2"
                @click="showModalFind(1)"
              >
                Cari <a-icon type="file-search" />
              </a-button>
              <!-- <a-button
                class="btn btn-outline-success ml-2"
                @click="showModalFind(2)"
              >
                Cari <a-icon type="file-search" />
              </a-button> -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Nama Nasabah</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <!-- <vue-typeahead-bootstrap
                class="mb-4"
                v-model="modalProperty.input.nama"
                @hit="selecteduser = $event"
                :data="modalProperty.table.rowData"
                :serializer="item => item.nama"
                @input="modalFind(false)"
                >
                <template slot="suggestion" slot-scope="{ data, htmlText }">
                  <div class="d-flex align-items-center">
                    <span class="ml-4" v-html="htmlText"></span>
                  </div>
                </template>
              </vue-typeahead-bootstrap> -->
              <!-- Note: the v-html binding is used, as htmlText contains the suggestion text highlighted with <strong> tags -->
              <a-input
                v-model="dataKode.nama"
                placeholder="Nama Nasabah"
                :readOnly="true"
                style="width: 300px"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Alamat Nasabah</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                v-model="dataKode.alamat"
                placeholder="Alamat Nasabah"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Telepon</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                v-model="dataKode.hp"
                placeholder="Telepon Nasabah"
                :readOnly="true"
                style="width: 150px"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Pekerjaan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                v-model="dataKode.nama_Pekerjaan"
                placeholder="Pekerjaan Nasabah"
                :readOnly="true"
                style="width: 250px"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Gol. Nasabah</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser-pos-relative"
                v-model="formData.golonganNasabah"
                placeholder="Gol. Nasabah"
                label="keterangan"
                :options="dataGolonganNasabah"
                :reduce="(tes) => tes.kode"
                style="width: 70%"
              />
              <a-input
                v-else
                placeholder="Golongan Nasabah"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Jenis Nasabah</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser"
                v-model="formData.jenisNasabah"
                placeholder="Jenis Nasabah"
                label="keterangan"
                :options="dataJenisNasabah"
                :reduce="(tes) => tes.kode"
                style="width: 30%"
              />
              <a-input
                v-else
                placeholder="Jenis Nasabah"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Karyawan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser"
                v-model="formData.karyawan"
                placeholder="Karyawan?"
                label="keterangan"
                :options="dataKaryawan"
                :reduce="(tes) => tes.kode"
                style="width: 20%"
              />
              <a-input v-else placeholder="Karyawan" :readOnly="true"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Keterkaitan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser"
                v-model="formData.keterkaitan"
                placeholder="Keterkaitan"
                label="keterangan"
                :options="dataKeterkaitan"
                :reduce="(tes) => tes.kode"
                style="width: 20%"
              />
              <a-input v-else placeholder="Keterkaitan" :readOnly="true"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>AO</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser"
                v-model="formData.ao"
                placeholder="Account Officer"
                label="keterangan"
                :options="dataAO"
                :reduce="(tes) => tes.kode"
                style="width: 50%"
              />
              <a-input v-else placeholder="AO" :readOnly="true"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Wilayah</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser"
                v-model="formData.wilayah"
                placeholder="Wilayah"
                label="keterangan"
                :options="dataWilayah"
                :reduce="(tes) => tes.kode"
                style="width: 50%"
              />
              <a-input v-else placeholder="Wilayah" :readOnly="true"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>GolonganTabungan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser"
                v-model="formData.golonganTabungan"
                placeholder="Golongan Tabungan"
                label="keterangan"
                :options="dataGolonganTabungan"
                :reduce="(tes) => tes.kode"
                style="width: 50%"
              />
              <a-input
                v-else
                placeholder="Golongan Tabungan"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>No. Rekening ATM</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                v-model="formData.nomorATM"
                placeholder="Nomor Rekening ATM"
                :readOnly="disabledAllInputExcludeNoRekening"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Nomor Buku</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                v-model="formData.nomorBuku"
                placeholder="Nomor Buku"
                :readOnly="disabledAllInputExcludeNoRekening"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Tabungan Emas</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                v-if="!disabledAllInputExcludeNoRekening"
                :clearable="false"
                class="style-chooser-pos-relative"
                v-model="formData.tabunganEmas"
                placeholder="Tabungan Emas?"
                label="keterangan"
                :options="dataTabunganEmas"
                :reduce="(tes) => tes.kode"
                style="width: 20%"
                @keydown.native.tab="focusToSaveButton"
                @keydown.native.enter="focusToSaveButton"
              />
              <a-input
                v-else
                @keydown.native.tab="focusToSaveButton"
                @keydown.native.enter="focusToSaveButton"
                placeholder="Tabungan Emas"
                :readOnly="true"
              ></a-input>
            </div>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal
      :footer="null"
      v-model="modalProperty1.visible"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="title">
        <strong>Cari Register</strong>
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label>Nama Nasabah</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input
            v-model="modalProperty1.input.nama"
            placeholder="Masukan Nama"
            :readOnly="false"
            @keydown.native.enter="modalFind1"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Alamat Nasabah</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            v-model="modalProperty1.input.alamat"
            placeholder="Masukan Alamat"
            :readOnly="modalProperty1.input.nama === ''"
            @keydown.native.enter="modalFind1"
          ></a-input>
        </div>
      </div>
      <ag-grid-vue
        style="height: 50vh"
        :class="
          settings.theme === 'dark'
            ? 'ag-theme-balham-dark mt-2'
            : 'ag-theme-balham mt-2'
        "
        :key="componentKey"
        :gridOptions="gridOptions"
        :columnDefs="modalProperty1.table.columnDefs"
        :pagination="false"
        :paginationPageSize="15"
        :cacheQuickFilter="true"
        :quickFilterText="tablequickfilter"
        :accentedSort="false"
        :sortingOrder="['asc', 'desc']"
        :rowData="modalProperty1.table.rowData"
        @grid-ready="onGridReady"
        @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
    </a-modal>
    <a-modal
      :footer="null"
      v-model="modalProperty.visible"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="title">
        <strong>Cari Register</strong>
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label>Nama Nasabah</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input
            v-model="modalProperty.input.nama"
            placeholder="Masukan Nama"
            :readOnly="false"
            @keydown.native.enter="modalFind"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Alamat Nasabah</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            v-model="modalProperty.input.alamat"
            placeholder="Masukan Alamat"
            :readOnly="modalProperty.input.nama === ''"
            @keydown.native.enter="modalFind"
          ></a-input>
        </div>
      </div>
      <ag-grid-vue
        style="height: 50vh"
        :class="
          settings.theme === 'dark'
            ? 'ag-theme-balham-dark mt-2'
            : 'ag-theme-balham mt-2'
        "
        :key="componentKey"
        :gridOptions="gridOptions"
        :columnDefs="modalProperty.table.columnDefs"
        :pagination="false"
        :paginationPageSize="15"
        :cacheQuickFilter="true"
        :quickFilterText="tablequickfilter"
        :accentedSort="false"
        :sortingOrder="['asc', 'desc']"
        :rowData="modalProperty.table.rowData"
        @grid-ready="onGridReady"
        @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
    </a-modal>
  </div>
</template>

<script>
// import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { TheMask } from 'vue-the-mask'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
// import { debounce } from 'lodash'

export default {
  components: {
    'the-mask': TheMask,
  // 'vue-typeahead-bootstrap': VueTypeaheadBootstrap,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    'modalProperty.input.nama' () {
      // debounce(function() { this.modalFind(false) }, 700)
      this.modalFind(false)
    },
    'modalProperty.input.alamat'() {
      // debounce(function() { this.modalFind(false) }, 700)
      this.modalFind(false)
    },
    'modalProperty1.input.nama' () {
      // debounce(function() { this.modalFind(false) }, 700)
      this.modalFind1(false)
    },
    'modalProperty1.input.alamat'() {
      // debounce(function() { this.modalFind(false) }, 700)
      this.modalFind1(false)
    },
    // addressSearch: _.debounce(function(addr) { this.getAddresses(addr) }, 500),
  },
  mounted() {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.getAllMaster()
    // this.getMaster()
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Pembukaan Tabungan'
      this.disabledAllInputExcludeNoRekening = true
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Pembukaan Tabungan'
      this.tempInput.f2digitreg = this.user.user_branch
    }
  },
  data() {
    return {
      selecteduser: null,
      disabledAllInputExcludeNoRekening: false,

      dateFormat: ['DD/MM/YYYY', 'DD/MM/YY'],
      form: this.$form.createForm(this),
      editdata: JSON.parse(
        localStorage.getItem('cbsjombangeditpembukaantabungandata'),
      ),
      alamatktpdantempattinggalsama: true,
      config: {
        rules: [
          { type: 'object', required: false, message: 'Tolong pilih tanggal!' },
        ],
      },
      errorMsg: null,
      successMsg: null,
      action: null,
      title: 'Pembukaan Tabungan',
      showHistory: true,
      showInformation: false,

      dataAO: [],
      dataKeterkaitan: [],
      dataGolonganNasabah: [],
      dataGolonganTabungan: [],
      dataWilayah: [],
      dataJenisNasabah: [
        {
          kode: '1',
          keterangan: 'Non Instansi',
        },
        {
          kode: '2',
          keterangan: 'Instansi',
        },
      ],
      dataKaryawan: [
        {
          kode: '0',
          keterangan: 'Tidak',
        },
        {
          kode: '1',
          keterangan: 'Ya',
        },
      ],
      dataTabunganEmas: [
        {
          kode: 'T',
          keterangan: 'Tidak',
        },
        {
          kode: 'Y',
          keterangan: 'Ya',
        },
      ],

      dataKode: {
        nama: '',
        alamat: '',
        hp: '',
        nama_Pekerjaan: '',
      },

      tempInput: {
        f2digitrek: '',
        s2digitrek: '',
        last6rek: '',
        f2digitreg: '',
        last6reg: '',
      },

      formData: {
        rekening: '',
        kode: '',
        tgl: moment().format('YYYY-MM-DD'),
        nomorBuku: '',
        ao: '',
        golonganNasabah: '',
        golonganTabungan: '',
        jenisNasabah: '',
        karyawan: '',
        keterkaitan: '',
        nomorATM: '',
        tabunganEmas: '',
        wilayah: '',
      },

      selectedData: {},
      tablequickfilter: '',
      gridOptions: null,
      modalProperty: {
        visible: false,
        input: {
          nama: '',
          alamat: '',
          kode: '',
        },
        table: {
          columnDefs: [
            {
              field: 'nama',
              headerName: 'Nama',
              maxWidth: 200,
              filter: true,
              sortable: true,
              resizable: true,
              cellRenderer: params => {
                // console.log('params', params)
                return '<span>' + params.value + '</span>'
              },
            },
            {
              field: 'alamat',
              headerName: 'Alamat',
              maxWidth: 250,
              filter: true,
              sortable: true,
              resizable: true,
              cellRenderer: params => {
                // console.log('params', params)
                return '<span>' + params.value + '</span>'
              },
            },
            {
              field: 'kode',
              headerName: 'No. Register',
              filter: true,
              sortable: true,
              resizable: true,
            },
          ],
          rowData: [
            // {
            //   kode: '01.000001',
            //   nama: 'Agus Hadi',
            //   alamat:
            //     'Jl. Aman Jaya 12 Malang fadsjfladflajdfkljsfadfkl a fkldjasfkl jk',
            //   hp: '081000010101',
            //   nama_Pekerjaan: 'PNS',
            // },
          ],
        },
      },
      modalProperty1: {
        visible: false,
        input: {
          nama: '',
          alamat: '',
          kode: '',
        },
        table: {
          columnDefs: [
            {
              field: 'nama',
              headerName: 'Nama',
              maxWidth: 200,
              filter: true,
              sortable: true,
              resizable: true,
              cellRenderer: params => {
                // console.log('params', params)
                return '<span>' + params.value + '</span>'
              },
            },
            {
              field: 'alamat',
              headerName: 'Alamat',
              maxWidth: 250,
              filter: true,
              sortable: true,
              resizable: true,
              cellRenderer: params => {
                // console.log('params', params)
                return '<span>' + params.value + '</span>'
              },
            },
            {
              field: 'kode',
              headerName: 'No. Register',
              filter: true,
              sortable: true,
              resizable: true,
            },
          ],
          rowData: [
            // {
            //   kode: '01.000001',
            //   nama: 'Agus Hadi',
            //   alamat:
            //     'Jl. Aman Jaya 12 Malang fadsjfladflajdfkljsfadfkl a fkldjasfkl jk',
            //   hp: '081000010101',
            //   nama_Pekerjaan: 'PNS',
            // },
          ],
        },
      },
      componentKey: 0,
    }
  },
  methods: {
    /* Debug Purposes */
    // dateRange(v, v2) {
    //   console.log('v', v)
    //   console.log('v2', v2)
    //   console.log('this.formData.tglktp', this.formData.tglktp)
    //   this.componentKey += 1
    // },
    moment,
    callback(key, v1) {
      console.log(key)
      console.log('this.formData.kode', this.formData.kode)
    },
    focusToSaveButton() {
      console.log('this.$refs.buttonsave', this.$refs.buttonsave)
      // window.scrollTo(0, 0)
      this.handleSubmit()
    },
    async modalFind1(withLoading = true) {
      // console.log('eak')
      if (this.modalProperty1.input.nama === '') {
        console.log('Input kosong!')
        this.modalProperty1.table.rowData = []
      } else {
        var response = await lou.customUrlGet('pembukaantabungan/cari?nama=' + this.modalProperty1.input.nama + '&alamat=' + this.modalProperty1.input.alamat, false, false, false, withLoading)
        if (response) {
          // console.log('response', response)
          this.modalProperty1.table.rowData = response.data
        }
      }
    },
    async modalFind(withLoading = true) {
      // console.log('eak')
      if (this.modalProperty.input.nama === '') {
        console.log('Input kosong!')
        this.modalProperty.table.rowData = []
      } else {
        var response = await lou.customUrlGet('pembukaantabungan/cari2?nama=' + this.modalProperty.input.nama + '&alamat=' + this.modalProperty.input.alamat, false, false, false, withLoading)
        if (response) {
          // console.log('response', response)
          var tempdata = []
          response.data.forEach(element => {
            var ret = {
              ...element.document,
            }
            if (element.highlights.length !== 0) {
              element.highlights.forEach(el => {
                ret[el.field] = el.snippet
              })
            }
            tempdata.push(ret)
          })
          this.modalProperty.table.rowData = tempdata
        }
      }
    },
    showModalFind(key) {
      switch (key) {
        case 1:
          this.modalProperty.visible = true

          break

        case 2:
          this.modalProperty1.visible = true

          break

        default:
          break
      }
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('this.selectedData', this.selectedData)
      var temp = this.selectedData[0].kode.split('.')
      this.tempInput.f2digitreg = temp[0]
      this.tempInput.last6reg = temp[1]
      this.formData.kode = this.tempInput.f2digitreg + '.' + this.tempInput.last6reg
      this.getDataRegister()
      // console.log('selectedRows', selectedRows[0].karat)
      // console.log('this.columnCurrency', this.columnCurrency)
    },
    async getDataRekening() {
      var t = this.tempInput.last6rek.padStart(6, '0')
      this.tempInput.last6rek = t
      var kode =
        this.tempInput.f2digitrek +
        '.' +
        this.tempInput.s2digitrek +
        '.' +
        this.tempInput.last6rek
      if (
        this.tempInput.f2digitrek !== '' &&
        this.tempInput.s2digitrek !== '' &&
        this.tempInput.last6rek
      ) {
        var res = await lou.customUrlGet('PembukaanTabungan/rekening/' + kode)
        if (res) {
          if (res.data) {
            this.disabledAllInputExcludeNoRekening = false
            this.dataKode.nama = res.data.nama
            this.dataKode.alamat = res.data.alamat
            this.dataKode.hp = res.data.hp
            this.dataKode.nama_Pekerjaan = res.data.pekerjaan
            this.formData.golonganNasabah = res.data.golongannasabah
            this.formData.jenisNasabah = res.data.jenisnasabah
            this.formData.golonganTabungan = res.data.golongantabungan
            this.formData.karyawan = res.data.karyawan
            this.formData.ao = res.data.ao
            this.formData.wilayah = res.data.wilayah
            this.formData.nomorATM = res.data.nomorATM
            this.formData.nomorBuku = res.data.nomorBuku
            this.formData.tabunganEmas = res.data.tabunganEmas
            // this.formData. = res.data.golonganTabungan
          } else {
            this.disabledAllInputExcludeNoRekening = true
            lou.shownotif('Alert!', 'Tidak ada data rekening!', 'info')
          }
        } else {
          this.disabledAllInputExcludeNoRekening = true
        }
      } else {
        this.disabledAllInputExcludeNoRekening = true
      }
    },
    async getDataRegister() {
      var kode = this.formData.kode
      if (kode !== '') {
        var res = await lou.customUrlGet('PembukaanTabungan/kode/' + kode)
        if (res) {
          this.dataKode = res.data
          this.modalProperty.visible = false
        }
      }
    },
    changeKode() {
      var tem = this.tempInput.last6reg.padStart(6, '0')
      // console.log('tem', tem)
      this.tempInput.last6reg = tem
      this.formData.kode = this.tempInput.f2digitreg + '.' + tem
      this.getDataRegister()
      // console.log('this.formData.kode', this.formData.kode)
    },
    async getEditData() {
      // console.log('this.editdata', this.editdata)
      // this.formData = this.$g.clone(this.editdata)
      this.formData.kode = this.editdata.kode
      this.formData.tgl = moment(this.formData.tgl, 'YYYY-MM-DD').format(
        'DD-MM-YYYY',
      )
      var tem1 = this.editdata.kode.split('.')
      this.tempInput.f2digitreg = tem1[0]
      this.tempInput.last6reg = tem1[1]
      var tem = this.editdata.rekening.split('.')
      this.tempInput.f2digitrek = tem[0]
      this.tempInput.s2digitrek = tem[1]
      this.tempInput.last6rek = tem[2]
      this.getDataRekening()
      // console.log('res', res.data)kode
      // console.log('this.editdata', this.editdata)
    },
    async getAllMaster() {
      var dataao = await lou.customUrlGet(
        'PembukaanTabungan/ao',
        false,
        false,
        false,
        false,
      )
      var dataketerkaitan = await lou.customUrlGet(
        'PembukaanTabungan/keterkaitan',
        false,
        false,
        false,
        false,
      )
      var datagolongannasabah = await lou.customUrlGet(
        'PembukaanTabungan/golongannasabah',
        false,
        false,
        false,
        false,
      )
      var datagolongantabungan = await lou.customUrlGet(
        'PembukaanTabungan/golongantabungan',
        false,
        false,
        false,
        false,
      )
      var datawilayah = await lou.customUrlGet(
        'PembukaanTabungan/wilayah',
        false,
        false,
        false,
        false,
      )
      // if (jenisidentitas) {
      //   this.datajenisidentitas = jenisidentitas.data
      //   this.allMaster.jenisidentitas = jenisidentitas.data
      // }

      this.allMaster = {}

      if (dataao) {
        var dao = []
        dataao.data.forEach((element) => {
          var el = element.kode + ' - ' + element.nama
          var v = {
            ...element,
            keterangan: el,
          }
          dao.push(v)
        })
        // console.log('kont', kont)
        this.dataAO = dao
        this.allMaster.ao = dataao.data
      }

      if (dataketerkaitan) {
        var keter = []
        dataketerkaitan.data.forEach((element) => {
          var el = element.kode + ' - ' + element.keterangan
          var elk = element.keterangan
          var v = {
            ...element,
            nama: elk,
            keterangan: el,
          }
          keter.push(v)
        })
        this.dataKeterkaitan = keter
        this.allMaster.keterkaitan = dataketerkaitan.data
      }

      if (datagolongannasabah) {
        var golnas = []
        datagolongannasabah.data.forEach((element) => {
          var el = element.kode + ' - ' + element.keterangan
          var elk = element.keterangan
          var v = {
            ...element,
            nama: elk,
            keterangan: el,
          }
          golnas.push(v)
        })
        this.dataGolonganNasabah = golnas
        this.allMaster.golongannasabah = datagolongannasabah.data
      }
      if (datagolongantabungan) {
        var goltab = []
        datagolongantabungan.data.forEach((element) => {
          var el = element.kode + ' - ' + element.keterangan
          var elk = element.keterangan
          var v = {
            ...element,
            nama: elk,
            keterangan: el,
          }
          goltab.push(v)
        })
        this.dataGolonganTabungan = goltab
        this.allMaster.golongantabungan = datagolongantabungan.data
      }

      if (datawilayah) {
        var wil = []
        datawilayah.data.forEach((element) => {
          var el = element.kode + ' - ' + element.keterangan
          var elk = element.keterangan
          var v = {
            ...element,
            nama: elk,
            keterangan: el,
          }
          wil.push(v)
        })
        this.dataWilayah = wil
        this.allMaster.wilayah = datawilayah.data
      }

      // setTimeout(() => {
      // this.addColumnDefs()
      // }, 1000)
    },
    handleSubmit(e) {
      Modal.confirm({
        title: 'Are you sure the data is correct?',
        content: 'You can edit the data later! ',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: async () => {
          // Should format date value before submit.
          var res = false
          var fd = this.formData
          try {
            fd.tgl =
              this.formData.tgl !== null
                ? this.formData.tgl.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tgl = this.formData.tgl !== null ? this.formData.tgl : null
          }
          if (this.action === 'update') {
            fd.rekening =
              this.tempInput.f2digitrek +
              '.' +
              this.tempInput.s2digitrek +
              '.' +
              this.tempInput.last6rek
            fd.tgl =
              this.formData.tgl !== null
                ? moment(this.formData.tgl, 'DD-MM-YYYY').format('YYYY-MM-DD')
                : null
            res = await lou.customUrlPut('pembukaantabungan', fd)
          } else {
            delete fd.id
            res = await lou.customUrlPost('pembukaantabungan', fd)
          }
          if (res) {
            this.$router.push('/cbsjombang/tabungan/pembukaan/data')
            // Modal.confirm({
            //   title: 'Kembali?',
            //   content: 'Kembali ke halaman data?! ',
            //   okText: 'Yes',
            //   okType: 'primary',
            //   cancelText: 'No',
            //   onOk: () => {
            //     // Should format date value before submit.
            //   },
            //   onCancel: () => {
            //     if (this.action !== 'update') {
            //       Modal.confirm({
            //         title: 'Reset Form?',
            //         content: 'Data Input Akan direset?! ',
            //         okText: 'Yes',
            //         okType: 'primary',
            //         cancelText: 'No',
            //         onOk: () => {
            //           this.resetForm()
            //         },
            //         onCancel: () => {
            //           fd.tgl =
            //             this.formData.tgl !== null
            //               ? moment(this.formData.tgl, 'YYYY-MM-DD')
            //               : moment()
            //           // console.log('Cancel')
            //         },
            //       })
            //     }
            //   },
            // })
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    nullFunction() {
      // console.log('%c! Nothing to see here! close debuging windows this instant or the app will automatically delete itself.%c', 'color: red; font-size:30px;', 'color: green; font-size:12px;')
      console.log(
        '%c! Hayooo... nyari apa disini... kalau error jangan di otak-atik sendiri, mending tanyakan team IT.%c',
        'color: red; font-size:30px;',
        'color: green; font-size:12px;',
      )
    },
    back() {
      this.resetForm()
      this.$router.push('/cbsjombang/tabungan/pembukaan/data')
    },
    resetForm() {
      localStorage.removeItem('cbsjombangeditpembukaantabungandata')
      this.formData = {
        kode: '',
        tgl: moment().format('YYYY-MM-DD'),
        nomorBuku: '',
        ao: '',
        golonganNasabah: '',
        golonganTabungan: '',
        jenisNasabah: '',
        karyawan: '',
        keterkaitan: '',
        nomorATM: '',
        tabunganEmas: '',
        wilayah: '',
      }
      // this.$router.push('/cbsjombang/tabungan/pembukaan/data')
    },
  },
}
</script>

<style>
.ant-modal-body {
  padding: 15px !important;
}
.special-input-class{
  background-color: black !important;
  color: white !important;
}
.special-highlight-class{
  font-weight: 900;
  color: #585656;
}
</style>
